import React, { Component } from "react";
import axios from "axios";
import * as api from "../../constants/api";
import { adalApiFetch } from "../../adalConfig";
import ReactTable from "react-table";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "react-table/react-table.css";
import ProductFamilyInfo from "../productfamily/ProductFamilyInfo";
import Modal from 'react-modal';
import { toastSuccess, toastError, customStyles} from '../../util/CommonFunctions'
import NavMenu from '../NavMenu'

Modal.setAppElement('#root')

class ProductFamilyList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            defaultOption: { key: 0, value: "All" },
            ProductFamilys: [],
            ProductFamilyCategories : [],
            DisplayedCount: 0,
            Count: 0,
            Take: 10,
            ShowFilters: true,
            Filters: {
            },
            OrgData: {
                prodFamilyId: 0,
                prodFamilyName: "",
                prodFamilyCategoryID: 0,
                uniqueRegByPAKID: false,
                ngcCreate: false,
                ngcUpdate: false,
                ngcProductName:"",
                pod: "NA",
                isPAKIDCheckRequired:false
            },
            IsOrgLock: false,
            Filter: [],
            IsLoaded: false,
            IsFiltering: false,
            isDialogOpen: false,
            isEditDialogOpen: false,
            autofocusName: true,
            autofocusNGCName: false,
            autofocusDescr: false,
            dataFilled: false
        };
    }

    componentDidMount() {
        this.getLookups();
        this.getInitial();
    }

    componentDidUpdate() {
        if (this.state.OrgData.prodFamilyName !== "" && this.state.OrgData.prodFamilyCategoryID !== 0) {
            if (this.state.dataFilled === false) {
                this.setState({ dataFilled: true });
            }
        }
        else {
            if (this.state.dataFilled === true) {
                this.setState({ dataFilled: false });
            }
        }
    }

    focusName = e => {
        this.setState({ autofocusName: true, autofocusDescr: false });
    }
   
    handleProductFamilyNameChange = e => {
        const val = e.target.value;
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.prodFamilyName = val;
        this.setState({ OrgData: orgData,autofocusName: true,autofocusNGCName:false});
       // this.focusName();
    };
    handleToggleChange = e => {
       
        const val =!this.state.OrgData.uniqueRegByPAKID;
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.uniqueRegByPAKID = val;
        this.setState({ OrgData: orgData});
       
    };

    handleProductFamilyCategoryChange = e => {
        const val = e.target.value;
        console.log(val);
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.prodFamilyCategoryID = parseInt(val);
        this.setState({ OrgData: orgData });
    }
    handlePODChange = e => {
        const val = e.target.value;
        console.log(val);
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.pod = val;
        this.setState({ OrgData: orgData });
    }
    handleNGCProductNameChange = e => {
        const val = e.target.value;
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.ngcProductName = val;
        this.setState({ OrgData: orgData,autofocusName: false,autofocusNGCName:true });
       // this.focusName();
    }
    handleNGCCreateToggleChange = e => {

        const val = !this.state.OrgData.ngcCreate;
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.ngcCreate = val;
        this.setState({ OrgData: orgData });

    };
    handleNGCUpdateToggleChange = e => {

        const val = !this.state.OrgData.ngcUpdate;
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.ngcUpdate = val;
        this.setState({ OrgData: orgData });

    };
    handleIsPAKIDCheckRequiredToggleChange = e => {

        const val = !this.state.OrgData.isPAKIDCheckRequired;
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.isPAKIDCheckRequired = val;
        this.setState({ OrgData: orgData });

    };
    openDialog(id) {
        this.setState({ isDialogOpen: true })
        this.setState({ rowID: id })
    }

    handleClose = () => this.setState({ isDialogOpen: false, isEditDialogOpen: false })

    render() {
        const {
            ProductFamilys: data,
            IsLoaded
        } = this.state;
        const { canCreateRWAMapping, canViewRWAMapping } = this.props;

        const columns = [
            {
                Header: "ID",
                accessor: "id",
                maxWidth: 50
            },
            {
                Header: "Name",
                accessor: "productFamilyName",
                filterMethod: this.defaultFilter,
                //maxWidth: 500
            },
            {
                Header: "Category",
                accessor: "productFamilyCategoryName",
                filterMethod: this.defaultFilter,
                maxWidth: 200
            },

            {
                id: 'uniqueRegByPAKID',
                 Header: "UniqueRegByPAKID",
              
                filterMethod: this.defaultFilter,
                maxWidth:150,
             
                accessor: d => { return d.uniqueRegByPAKID ? 'Yes' : 'No' },
            },
            {
                id: 'NGCCreate',
                Header: "NGC Create",

                filterMethod: this.defaultFilter,
                maxWidth: 100,

                accessor: d => { return d.ngcCreate ? 'Yes' : 'No' },
            },
            {
                id: 'NGCUpdate',
                Header: "NGC Update",

                filterMethod: this.defaultFilter,
                maxWidth: 100,

                accessor: d => { return d.ngcUpdate ? 'Yes' : 'No' },
            },
            {
                id: 'NGCProductName',
                Header: "NGC Product Name",

                filterMethod: this.defaultFilter,
                maxWidth: 200,

                accessor: d => { return d.ngcProductName ? d.ngcProductName : '' },
            },
            {
                id: 'POD',
                 Header: "POD",
              
                filterMethod: this.defaultFilter,
                maxWidth:50,
             
                accessor: d => { return d.pod ? d.pod : 'NA' },
            },
            {
                id: 'IsPAKIDCheckRequired',
                Header: "PAKID Check Required",

                filterMethod: this.defaultFilter,
                maxWidth: 150,

                accessor: d => { return d.isPAKIDCheckRequired ? 'Yes' : 'No' },
            },
            {
                Header: "Edit",
                style: { flex: "1" },
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                filterable: false,
                sortable: false,
                Cell: props => {
                    return (
                        <div>
                            {canCreateRWAMapping && (
                                <div>
                                    <button className="btn small"
                                        onClick={() => {
                                            let selRow = props.row._original;
                                            this.handleClickEdit(selRow);
                                        }} >
                                        <FontAwesomeIcon icon="edit" color="Grey" />
                                    </button>
                                    <Modal isOpen={this.state.isEditDialogOpen} style={customStyles} overlayClassName="Overlay"
                                    >
                                        <div>
                                            <div className="modalDivHeader">
                                            </div>
                                            <div className="details-section-header modalLabel">
                                                <label className="modLabel"> Product Family Maintenance </label>
                                                <button id="btnClose" onClick={this.handleClose}>  X  </button>
                                            </div>
                                            <ProductFamilyInfo
                                                orgData={this.state.OrgData}
                                                prodFamilyCategories={this.state.ProductFamilyCategories}
                                                handleProductFamilyNameChange={this.handleProductFamilyNameChange}
                                                handleToggleChange={this.handleToggleChange}
                                                handleProductFamilyCategoryChange={this.handleProductFamilyCategoryChange}
                                                handleNGCCreateToggleChange={this.handleNGCCreateToggleChange}
                                                handleNGCUpdateToggleChange={this.handleNGCUpdateToggleChange}
                                                handlePODChange={this.handlePODChange}
                                                handleNGCProductNameChange={this.handleNGCProductNameChange}
                                                handleIsPAKIDCheckRequiredToggleChange={this.handleIsPAKIDCheckRequiredToggleChange}
                                                autoFocusName={this.state.autofocusName}
                                                autoFocusNGCName={this.state.autofocusNGCName}
                                                dataFilled={this.state.dataFilled}
                                                onSaveClick={this.handleSaveClick} />
                                        </div>
                                    </Modal>
                                </div>
                            )}
                        </div>
                    );
                }
            },
            {
                Header: "Delete",
                style: { flex: "1" },
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                filterable: false,
                sortable: false,
                Cell: props => {
                    return (
                        <div>
                            {canCreateRWAMapping && (
                                <div>
                                    <button className="btn small"
                                        onClick={() => { this.openDialog(props.row._original.id); }} >
                                        <FontAwesomeIcon icon="trash" color="Grey" />
                                    </button>
                                    <Modal isOpen={this.state.isDialogOpen} style={customStyles} overlayClassName="Overlay" >
                                        <div>
                                            <div className="modalDivHeader">
                                            </div>
                                            <div className="details-section-header modalLabel">
                                                <label className="modalLabel">  Delete Product Family </label>
                                                <button id="btnClose" onClick={this.handleClose}>  X  </button>
                                            </div>
                                            <h4 className="lblNVGreen">Are you sure you want to delete record {this.state.rowID} ?</h4>
                                            <center>
                                                <button className="btn" onClick={() => this.handleClickDelete(this.state.rowID)}> Yes </button>
                                            </center>
                                        </div>
                                    </Modal>
                                </div>
                            )}
                        </div>
                    );
                }
            },
        ];

        if (!IsLoaded) {
            return (
                <div className="loading-full">
                    <FontAwesomeIcon
                        icon="spinner"
                        spin
                        className="color-nvgreen"
                        size="2x"
                    />
                    <div className="loading-text">loading page content</div>
                </div>
            );
        }

        return (
            <div>
                {canViewRWAMapping && (
                    <NavMenu />
                )}
                <div className="flex-fill">
                    <div className="divAddBtn">
                         <button className="btn" id="btnAdd" onClick={() => { this.handleClickEdit() }} >
                            <FontAwesomeIcon icon="plus" />
                        </button>
                    </div>
                </div>
                <div className="content" >
                    <h4 className="lblNVGreen"> Product Families </h4>
                    {canViewRWAMapping && (
                        <div>

                            <ReactTable
                                data={data}
                                columns={columns}
                                defaultPageSize={this.state.Take}
                                minRows={0}
                                filterable={this.state.ShowFilters}
                                resizable={false}
                                className="-striped -highlight"
                                filtered={this.state.Filter}
                                onFilteredChange={filtered => {
                                    this.setState({ Filter: filtered });
                                }}
                            >
                                {(state, makeTable, instance) => {
                                    let recordsInfoText = "";

                                    const { filtered, pageRows, pageSize, sortedData, page } = state;

                                    if (sortedData && sortedData.length > 0) {
                                        let isFiltered = filtered.length > 0;
                                        let totalRecords = sortedData.length;
                                        let recordsCountFrom = page * pageSize + 1;
                                        let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                                        if (isFiltered)
                                            recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} filtered records`;
                                        else
                                            recordsInfoText = `${recordsCountFrom}-${recordsCountTo} of ${totalRecords} records`;
                                    } else recordsInfoText = "No records";

                                    const dashboard = (
                                        <div className="dashboard">
                                            <div className="dashboard-text">{recordsInfoText}</div>
                                        </div>
                                    );
                                    return (
                                        <div>
                                            {dashboard}
                                            {makeTable()}
                                        </div>
                                    );
                                }}
                            </ReactTable>
                            <div id="divTbl"> </div>
                        </div>
                    )}
                </div>

            </div>
        );
    }
    handleSaveClick = e => {
        let orgData = Object.assign({}, this.state.OrgData);
        let prodFamilyID = parseInt(orgData.prodFamilyId) ? parseInt(orgData.prodFamilyId) : 0;

        let data = {
            Id: prodFamilyID,
            ProductFamilyName: orgData.prodFamilyName,
            ProductFamilyCategoryID: orgData.prodFamilyCategoryID,
            uniqueRegByPAKID: orgData.uniqueRegByPAKID,
            NGC_Create: orgData.ngcCreate,
            NGC_Update: orgData.ngcUpdate,
            NGCProductName: orgData.ngcProductName,
            POD: orgData.pod,
            IsPAKIDCheckRequired: orgData.isPAKIDCheckRequired
        };

        let url = "";
        if (prodFamilyID === 0) {
            url = api.POST_CREATE_PRODUCT_FAMILIES;
        }
        else {
            url = api.POST_UPDATE_PRODUCT_FAMILIES + "/" + prodFamilyID;
        }
        console.log(data);
        adalApiFetch(
            axios,
            url,
            {
                method: "post",
                data: data,
                //cancelToken: this.source.token
            }
        )
        .then(response => {
            console.log(response);
            this.getInitial();
            this.resetElements();
            toastSuccess(response.data["Data"]);
        })
            .catch(error => {
                console.log(error);
                if (error.response) {
                    if (error.response.data.title)
                        toastError(JSON.stringify(error.response.data["errors"]));
                    else
                        toastError(error.response.data["Message"]);
                }
        });
    }

    resetElements = e => {
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.prodFamilyId = 0;
        orgData.prodFamilyName = "";
        orgData.prodFamilyCategoryID = 0;
        orgData.ngcCreate = 0;
        orgData.ngcUpdate = 0;
        orgData.ngcProductName = "";
        orgData.pod = "NA";
        orgData.isPAKIDCheckRequired = 0;
        this.setState({ OrgData: orgData, isDialogOpen: false, isEditDialogOpen: false }, function () { });
    }

    handleClickEdit(selRow) {
        
        console.log(selRow);
        this.setState({ isEditDialogOpen: true })
        let orgData = Object.assign({}, this.state.OrgData);
        orgData.prodFamilyId = selRow ? selRow.id : 0;
        orgData.prodFamilyName = selRow ? selRow.productFamilyName : "";
        orgData.prodFamilyCategoryID = selRow ? selRow.productFamilyCategoryID : 0;
        orgData.uniqueRegByPAKID = selRow ? selRow.uniqueRegByPAKID : false;
        orgData.ngcCreate = selRow ? selRow.ngcCreate : false;
        orgData.ngcUpdate = selRow ? selRow.ngcUpdate : false;
        orgData.ngcProductName = selRow ? selRow.ngcProductName : "";
        orgData.pod = selRow ? selRow.pod : "NA";
        orgData.isPAKIDCheckRequired = selRow ? selRow.isPAKIDCheckRequired : false;
        this.setState({ OrgData: orgData, isEditDialogOpen: true }, function () {
            console.log(this.state.OrgData);
        });
        this.focusName();
    }

    handleClickDelete(props) {
        adalApiFetch(
            axios,
            api.POST_DELETE_PRODUCT_FAMILIES + '/' + parseInt(props),
            {
                method: "post",
                //cancelToken: this.source.token
            }
        )
        .then(res => {
            console.log(res.data);
            this.handleClose();
            this.getInitial();
            toastSuccess(res.data["Data"]);
        })
        .catch(error => {
            console.log(error);
            if (error.response) {
                console.log(error.response);
                toastError(error.response.data["Message"]);
            }
        });
    }

    defaultFilter(filter, row, column) {
        return (
            row[filter.id] &&
            row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
        );
    }

    buildFilter() {
        let filterString = "";
        return filterString;
    }

    getInitial(useFilter) {
        
        const filterString = this.buildFilter();
        let options = {
            params: {
                //take: this.state.Take,
                filter: filterString
            }
        };

        if (!useFilter) {
            delete options.params;
        }
        adalApiFetch(axios, api.GET_PRODUCT_FAMILIES, options)
            .then(response => {
                const modifiedData = response.data.records.map(r => {
                    return r;
                });

                this.setState({
                    IsLoaded: true,
                    ProductFamilys: modifiedData,
                    Count: response.data.totalCount,
                    DisplayedCount: this.state.Take,
                    GetMoreEnabled: true
                });
            })
            .catch(error => {
                console.log(error.response);
            });
    }

    getLookups() {
        adalApiFetch(axios, api.GET_RWA_LOOKUPS, {})
            .then(response => {
                response.data.ProductFamilyCategory.unshift(this.state.defaultOption);
                this.setState({
                    ProductFamilyCategories: response.data.ProductFamilyCategory,
 
                });
            })
            .catch(error => {
                console.log(error);
                if (error.response) {
                    console.log(error.response);
                }
            });
    }

}

export default ProductFamilyList;

