import React,{useState} from "react";
import Input from "../../util/Input";
import { PropTypes } from "prop-types";
import Select from "../../util/Select";
import {Toggle} from "../../util/Toggle";

const ProductFamiyInfo = props => {

    const [selected, setSelected] = useState(props.orgData.uniqueRegByPAKID);
    function handleClick() {
        // Similar to this.setState({ fruit: 'orange' })
        setSelected(!selected);
      }
   
    return (
        <div className="AppSection">

            <div className="leftColumn margin-height">
                <Input
                    labelText={"Product Family Name"}
                    className={"margin-height lblNVGreen required"}
                    inputClassName={"margin-leftInput"}
                    isRequired={true}
                    autoFocus={props.autoFocusName}
                    id={"aName"}
                    value={props.orgData.prodFamilyName}
                    onChange={props.handleProductFamilyNameChange}
                />

                <Select
                    labelText={"Product Family Category"}
                    className={"margin-height lblNVGreen required "}
                    inputClassName={"selectWidthSelect"}
                    optionValues={props.prodFamilyCategories}
                    isRequired={true}
                    value={props.orgData.prodFamilyCategoryID}
                    onChange={props.handleProductFamilyCategoryChange}
                    displayFieldName="value"
                    valueFieldName="key"
                />
               
                <div style={{marginTop:'10px'}}> 
                    <label className="margin-height lblNVGreen  ">Unique Registration By PAKID:</label>
                   

                    <div style={{ display: 'inline-block', position: 'fixed', left: '53%'}}>
                    <Toggle
            selected={props.orgData.uniqueRegByPAKID}
            toggleSelected={props.handleToggleChange}
              />
            
               </div>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                    <div >
                        <label className="margin-height lblNVGreen  ">NGC Create:</label>
                    </div>
                    <div style={{display: 'inline-block', position: 'fixed',left: '53%' }}>
                        <Toggle
                            selected={props.orgData.ngcCreate}
                            toggleSelected={props.handleNGCCreateToggleChange}
                        />
                     </div>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                    <div >
                        <label className="margin-height lblNVGreen  ">NGC Update:</label>
                    </div>
                    <div style={{ display: 'inline-block', position: 'fixed', left: '53%' }}>
                        <Toggle
                            selected={props.orgData.ngcUpdate}
                            toggleSelected={props.handleNGCUpdateToggleChange}
                        />
                    </div>
                </div>
                <Input
                    labelText={"   NGC Product Name"}
                    className={"margin-height lblNVGreen"}
                    inputClassName={"margin-leftInputNGC"}
                    autoFocus={props.autoFocusNGCName}
                    id={"aNGCProductName"}
                    value={props.orgData.ngcProductName}
                    onChange={props.handleNGCProductNameChange}
                />
                <Select
                    labelText={"   POD Confirmation"}
                    className={"margin-height lblNVGreen required "}
                    inputClassName={"selectWidthSelect"}
                    optionValues={[{ 'key': 'Auto', 'value': 'Auto' }, { 'key': 'Confirmation Required', 'value': 'Confirmation Required' }, { 'key': 'NA', 'value': 'NA' }]}
                    isRequired={true}
                    value={props.orgData.pod}
                    onChange={props.handlePODChange}
                    displayFieldName="value"
                    valueFieldName="key"
                />
                <div className="row" style={{ marginTop: '10px' }}>
                    <div >
                        <label className="margin-height lblNVGreen  ">PAKID Check Required:</label>
                    </div>
                    <div style={{ display: 'inline-block', position: 'fixed', left: '53%' }}>
                        <Toggle
                            selected={props.orgData.isPAKIDCheckRequired}
                            toggleSelected={props.handleIsPAKIDCheckRequiredToggleChange}
                        />
                    </div>
                </div>
                <div className="flex-fill margin-height">
                    <center><button className="btn" onClick={props.onSaveClick} disabled={!props.dataFilled} > Save  </button> </center>
                </div>
            </div>
        </div>
    );
};

ProductFamiyInfo.propTypes = {
    isRequired: PropTypes.bool,
    autoFocusName: PropTypes.bool,
    autoFocusDescr: PropTypes.bool,
    orgData: PropTypes.object,
    handleProductFamilyNameChange: PropTypes.func.isRequired,
    handleProductFamilyCategoryChange: PropTypes.func.isRequired,
    handlePODChange: PropTypes.func.isRequired,
    handleNGCProductNameChange: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func,
    dataFilled: PropTypes.bool
};

export default ProductFamiyInfo;
